// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import {
  SecurityFrameworksStandardsDto,
  castSecurityFrameworkStandardToSecurityFrameworkStandardDto,
} from '@/types/security-framework/dtos/standards';
import type { SecurityFrameworkStandard } from '@/types/security-framework/standards';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  standardSecurityFramework: z.enum(SecurityFrameworksStandardsDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodForkSecurityFrameworkStandardRequestPayloadDto };
export type { RequestPayloadDto as ForkSecurityFrameworkStandardRequestPayloadDto };

// #######
// Request
// #######

export const forkSecurityFrameworkStandard = async (
  standard: SecurityFrameworkStandard
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      standardSecurityFramework:
        castSecurityFrameworkStandardToSecurityFrameworkStandardDto(standard),
    });

    return await apiClient.post('/SecurityFramework/CreateSecurityFrameworkFromStandard', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseForkSecurityFrameworkStandard = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useForkSecurityFrameworkStandard = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseForkSecurityFrameworkStandard) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (standard: SecurityFrameworkStandard) =>
      await forkSecurityFrameworkStandard(standard),
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    forkSecurityFrameworkStandard: mutate,
    isCreating: isPending,
    didCreationErrored: isError,
  };
};
