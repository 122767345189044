// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityFrameworkId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCloneFrameworkRequestPayloadDto };
export type { RequestPayloadDto as CloneFrameworkRequestPayloadDto };

// #######
// Request
// #######

export const cloneFramework = async (frameworkId: string): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityFrameworkId: frameworkId,
    });

    return await apiClient.post('/SecurityFramework/DuplicateSecurityFramework', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCloneFramework = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useCloneFramework = ({ onStart, onSuccess, onError }: UseCloneFramework = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (frameworkId: string) => await cloneFramework(frameworkId),
    onMutate: () => {
      // onMutate: async (model) => {
      // await queryClient.cancelQueries({
      //   queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      // });
      //
      // const previousFrameworks = queryClient.getQueryData(
      //   SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks() || []
      // ) as SecurityFramework[];
      // const newFrameworks = previousFrameworks.flatMap((framework) => {
      //   const updatedControl = framework.controls.map((control) => {
      //     const updatedRequirements = control.requirements.filter(
      //       (requirement) => requirement.id !== model
      //     );
      //
      //     return {
      //       ...control,
      //       requirements: updatedRequirements,
      //     };
      //   });
      //
      //   return {
      //     ...framework,
      //     controls: updatedControl,
      //   };
      // });
      //
      // queryClient.setQueryData(SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(), newFrameworks);

      onStart?.();

      // return { previousFrameworks, newFrameworks };
    },
    onSuccess: async () => {
      // await queryClient.cancelQueries({
      //   queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      // });
      //
      // const previousFrameworks = queryClient.getQueryData(
      //   SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks() || []
      // ) as SecurityFramework[];
      // const newFrameworks = previousFrameworks.filter((framework) => {
      //   return framework.id !== deletedFrameworkId;
      // });
      //
      // queryClient.setQueryData(SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(), newFrameworks);

      onSuccess?.();
    },
    onError: (error) => {
      // queryClient.invalidateQueries({
      //   queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      // });

      onError?.(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      });
    },
  });

  return {
    cloneFramework: mutate,
    isCloningFramework: isPending,
    didCloneFrameworkErrored: isError,
  };
};
