// 3rd
import { z } from 'zod';

// App - Types
import {
  ActivityStatusesDto,
  castActivityStatusDtoToBoolean,
} from '@/types/security-framework/dtos/activity-status';
import {
  SecurityFrameworksSourcesDto,
  castSecurityFrameworkSourceDtoToSecurityFrameworkSource,
} from '@/types/security-framework/dtos/source';
import {
  SecurityFrameworksTypesDto,
  castSecurityFrameworkTypeDtoToSecurityFrameworkType,
} from '@/types/security-framework/dtos/type';
import type { SecurityFramework } from '../../types/security-framework';
import { ZodControlDto, castControlDtoToControl } from './control';

export const ZodSecurityFrameworkDto = z.object({
  id: z.string(),
  name: z.string(),
  frameworkDescription: z.string().optional(),
  activityStatus: z.enum(ActivityStatusesDto),
  securityControls: z.array(ZodControlDto),
  labels: z.array(z.string()),
  source: z.enum(SecurityFrameworksSourcesDto),
  type: z.enum(SecurityFrameworksTypesDto),
  isDefault: z.boolean(),
});

export type SecurityFrameworkDto = z.infer<typeof ZodSecurityFrameworkDto>;

export const castSecurityFrameworkDtoToSecurityFramework = (
  dto: SecurityFrameworkDto
): SecurityFramework => {
  return {
    id: dto.id,
    name: dto.name,
    source: castSecurityFrameworkSourceDtoToSecurityFrameworkSource(dto.source),
    type: castSecurityFrameworkTypeDtoToSecurityFrameworkType(dto.type),
    labels: dto.labels,
    description: dto.frameworkDescription,
    isActive: castActivityStatusDtoToBoolean(dto.activityStatus),
    controls: dto.securityControls.map((control) => castControlDtoToControl(control, dto.name)),
  };
};
