// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import {
  CountermeasureSnippetTypesDto,
  castCountermeasureSnippetTypeToCountermeasureSnippetTypeDto,
} from '@/types/security-framework/dtos/countermeasure-snippet-type';
import {
  CountermeasureSourcesDto,
  castCountermeasureSourceToCountermeasureSourceDto,
} from '@/types/security-framework/dtos/countermeasure-source';
import type { Countermeasure } from '../types/countermeasure';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

type CreateCountermeasureMutationPayload = {
  requirementId: string;
  countermeasure: Countermeasure;
};

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityRequirementsIds: z.string().array(),
  content: z.string(),
  title: z.string(),
  countermeasureSnippetType: z.enum(CountermeasureSnippetTypesDto),
  countermeasureSource: z.enum(CountermeasureSourcesDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateCountermeasureRequestPayloadDto };
export type { RequestPayloadDto as CreateCountermeasureRequestPayloadDto };

// #######
// Request
// #######

export const createCountermeasure = async ({
  requirementId,
  countermeasure,
}: CreateCountermeasureMutationPayload): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityRequirementsIds: [requirementId],
      content: countermeasure.content,
      title: countermeasure.title,
      countermeasureSnippetType: castCountermeasureSnippetTypeToCountermeasureSnippetTypeDto(
        countermeasure.snippetType
      ),
      countermeasureSource: castCountermeasureSourceToCountermeasureSourceDto(
        countermeasure.source
      ),
    });

    return await apiClient.post('/SecurityFramework/CreateCountermeasure', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateCountermeasure = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateCountermeasure = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateCountermeasure = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateCountermeasureMutationPayload) =>
      await createCountermeasure(model),
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    createCountermeasure: mutate,
    isCreating: isPending,
    didCreationErrored: isError,
  };
};
