// React & Next
import { useMemo } from 'react';

// 3rd
import { Box, Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

// App - Types
import type { SecurityFramework } from '../../types/security-framework';

// App - Other
import { PrioritiesBubbleChart } from '@/components/molecules/charts';

type FrameworksControlsImportanceBubbleChartProps = {
  frameworks: SecurityFramework[];
  canvasWidth: number;
  canvasHeight: number;
};

export const FrameworksControlsImportanceBubbleChart = ({
  frameworks,
  canvasWidth,
  canvasHeight,
}: FrameworksControlsImportanceBubbleChartProps) => {
  const importanceDistribution = useMemo(() => {
    return frameworks.reduce(
      (acc, framework) => {
        framework.controls.forEach((control) => {
          if (control.importance === 'UNKNOWN') return;

          acc[control.importance] += 1;
        });

        return acc;
      },
      { high: 0, medium: 0, low: 0 }
    );
  }, [frameworks]);

  return (
    <Box width={`${canvasWidth}px`} height={`${canvasHeight}px`} bg="surface.primary">
      <PrioritiesBubbleChart
        size="medium"
        highCount={importanceDistribution.high}
        mediumCount={importanceDistribution.medium}
        lowCount={importanceDistribution.low}
      />
    </Box>
  );
};

type LoadingProps = {
  canvasWidth: number;
  canvasHeight: number;
};

const Loading = ({ canvasWidth, canvasHeight }: LoadingProps) => {
  return (
    <Box width={`${canvasWidth}px`} height={`${canvasHeight}px`} bg="surface.primary">
      <Flex alignItems="center" justifyContent="space-between" h="100%" pe="44px" ps="8px">
        <Flex direction="column" mr="16px" gap="8px">
          <Flex alignItems="center" gap="6px">
            <SkeletonCircle size="12px" />

            <Skeleton height="12px" width="35px" />
          </Flex>

          <Flex alignItems="center" gap="6px">
            <SkeletonCircle size="12px" />

            <Skeleton height="12px" width="35px" />
          </Flex>

          <Flex alignItems="center" gap="6px">
            <SkeletonCircle size="12px" />

            <Skeleton height="12px" width="35px" />
          </Flex>
        </Flex>

        <Flex direction="column" position="relative" top="-44px">
          <SkeletonCircle
            size="62px"
            position="absolute"
            top="10px"
            right="14px"
            startColor="#edf2f7"
            endColor="#a0aec0"
            opacity={1}
            zIndex={1}
          />

          <SkeletonCircle
            size="53px"
            position="absolute"
            top="37px"
            right="45px"
            startColor="#f7fafc"
            endColor="#a0aec0"
            opacity={1}
          />

          <SkeletonCircle
            size="44px"
            position="absolute"
            top="45px"
            right="-4px"
            startColor="#f7fafc"
            endColor="#a0aec0"
            opacity={1}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

FrameworksControlsImportanceBubbleChart.Loading = Loading;
