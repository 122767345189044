// App - Types
import type { ImportanceLevel } from '../importance-level';

export const ImportanceLevelsDto = ['High', 'Medium', 'Low', 'UNKNOWN'] as const;

export type ImportanceLevelDto = (typeof ImportanceLevelsDto)[number];

export const castImportanceLevelDtoToImportanceLevel = (
  dto: ImportanceLevelDto
): ImportanceLevel => {
  if (dto === 'High') return 'high';

  if (dto === 'Medium') return 'medium';

  if (dto === 'Low') return 'low';

  return 'UNKNOWN';
};

export const castImportanceLevelToImportanceLevelDto = (
  importanceLevel: ImportanceLevel
): ImportanceLevelDto => {
  if (importanceLevel === 'high') return 'High';

  if (importanceLevel === 'medium') return 'Medium';

  if (importanceLevel === 'low') return 'Low';

  return 'UNKNOWN';
};
