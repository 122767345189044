// 3rd
import { z } from 'zod';

// App - Types
import {
  CountermeasureSourcesDto,
  castCountermeasureSourceDtoToCountermeasureSource,
} from '@/types/security-framework/dtos/countermeasure-source';
import {
  CountermeasureSnippetTypesDto,
  castCountermeasureSnippetTypeDtoToCountermeasureSnippetType,
} from '@/types/security-framework/dtos/countermeasure-snippet-type';
import type { Countermeasure } from '../../types/countermeasure';

export const ZodCountermeasureDto = z.object({
  id: z.string(),
  content: z.string(),
  title: z.string(),
  countermeasureSource: z.enum(CountermeasureSourcesDto),
  countermeasureSnippetType: z.enum(CountermeasureSnippetTypesDto),
  securityRequirementsIds: z.string().array(),
});

export type CountermeasureDto = z.infer<typeof ZodCountermeasureDto>;

export const castCountermeasureDtoToCountermeasure = (dto: CountermeasureDto): Countermeasure => {
  return {
    content: dto.content,
    source: castCountermeasureSourceDtoToCountermeasureSource(dto.countermeasureSource),
    snippetType: castCountermeasureSnippetTypeDtoToCountermeasureSnippetType(
      dto.countermeasureSnippetType
    ),
    title: dto.title,
  };
};
