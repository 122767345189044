// 3rd
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  requirementIdToOccurrenceCount: z
    .record(z.string(), z.number())
    .default({})
    .catch((e) => {
      console.error('framework requirements violations parsing errored! see:', e);

      return {};
    }),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetFrameworkRequirementsViolationsResponseDto };
export type { ResponseDto as GetFrameworkRequirementsViolationsResponseDto };

// #######
// Request
// #######

export const getFrameworkRequirementsViolations = async (): Promise<Record<string, number>> => {
  try {
    const res = await apiClient.get(`/SecurityFramework/GetSecurityRequirementsAppearanceCount`);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.requirementIdToOccurrenceCount;
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return {};
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseFrameworkRequirementsViolations = {
  enabled?: boolean;
};

export const useFrameworkRequirementsViolations = ({
  enabled = true,
}: UseFrameworkRequirementsViolations = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworksRequirementsViolation(),
    queryFn: async () => await getFrameworkRequirementsViolations(),
    enabled: enabled,
  });

  return {
    violations: data,
    isFetchingViolations: isLoading || isFetching,
    didFetchingViolationsErrored: isLoadingError,
  };
};
