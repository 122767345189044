// React & Next
// import { useMemo } from 'react';

// 3rd
import { Flex, Stack, Textarea, useToken } from '@chakra-ui/react';
// import { useForm, Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';

// App - Types
// import { AntiPatternTypes } from '../../types/requirements-framework';
import type { CreateAntiPattern } from '../../types/anti-pattern';

// App - Other
import Locale from '@/locale/en.json';
import { InfoIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { Field } from '@/components/molecules/form';
// import { Field, Input, Select } from '@/components/molecules/form';
import { Button } from '@/components/molecules/button';
import { Tooltip } from '@/components/molecules/tooltips';
// import { translateType } from '../translators/anti-pattern/type';

const locale = Locale.features.frameworks['frameworks-requirement-anti-pattern-form'];

type CountermeasureFormProps = {
  onSubmit: (antiPattern: CreateAntiPattern) => void;
  isSubmitting?: boolean;
  didError?: boolean;
};

export const AntiPatternForm = ({ onSubmit, isSubmitting, didError }: CountermeasureFormProps) => {
  const [defaultOutlineColor] = useToken('colors', ['border.primary']);
  // const { register, handleSubmit, formState, control } = useForm<AntiPattern>();
  const { register, handleSubmit, formState } = useForm<CreateAntiPattern>();

  // const typesOptions = useMemo(
  //   () =>
  //     AntiPatternTypes.flatMap((type) => {
  //       const translatedType = translateType(type);
  //
  //       if (translatedType) {
  //         return [
  //           {
  //             value: type,
  //             label: translatedType,
  //           },
  //         ];
  //       }
  //
  //       return [];
  //     }),
  //   []
  // );

  return (
    <Stack
      as="form"
      direction="column"
      alignItems="flex-start"
      gap="none"
      position="relative"
      width="100%"
      height="fit-content"
      outline={`1px solid ${defaultOutlineColor}`}
      borderRadius="sm"
      transition="150ms all ease-in-out"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex
        alignItems="center"
        gap="md"
        py="8px"
        ps="12px"
        pe="12px"
        w="100%"
        borderBottom="1px"
        borderColor="border.primary"
      >
        <Text variant="detail" color="text.high-tone">
          {locale['New anti-pattern']}
        </Text>

        {/*<Controller*/}
        {/*  control={control}*/}
        {/*  name="type"*/}
        {/*  rules={{ required: 'Required' }}*/}
        {/*  render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (*/}
        {/*    <Field label="" htmlFor="type" error={error?.message} w="fit-content">*/}
        {/*      <Select*/}
        {/*        id={name}*/}
        {/*        useBasicStyles*/}
        {/*        variant="old.outline"*/}
        {/*        size="sm"*/}
        {/*        options={typesOptions}*/}
        {/*        value={typesOptions.find(({ value: type }) => type === value)}*/}
        {/*        selectedOptionColor="surface.brand.primary"*/}
        {/*        isDisabled={isSubmitting}*/}
        {/*        onChange={(newValue) => {*/}
        {/*          onChange(newValue?.value);*/}
        {/*        }}*/}
        {/*        onBlur={onBlur}*/}
        {/*        ref={ref}*/}
        {/*        chakraStyles={{*/}
        {/*          container: (styles) => ({*/}
        {/*            ...styles,*/}
        {/*            maxW: '250px',*/}
        {/*            minW: '115px',*/}
        {/*            w: 'fit-content',*/}
        {/*          }),*/}
        {/*          menuList: (styles) => ({*/}
        {/*            ...styles,*/}
        {/*            maxW: '250px',*/}
        {/*            minW: '115px',*/}
        {/*            w: '100%',*/}
        {/*          }),*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </Field>*/}
        {/*  )}*/}
        {/*/>*/}

        <Flex grow={1} />

        <Flex alignItems="center" gap="md">
          {didError ? (
            <Tooltip content={locale['Something isn’t right, let’s try again.']}>
              <InfoIcon size="xs" color="text.error" aria-label={locale['Error info tip']} />
            </Tooltip>
          ) : null}

          <Button
            variant="old.solid"
            size="sm"
            isLoading={isSubmitting}
            type="submit"
            aria-label={locale['Create']}
          >
            {locale['Create']}
          </Button>
        </Flex>
      </Flex>

      <Flex py="md" px="md" w="100%">
        <Field label="" htmlFor="content" error={formState.errors['content']?.message}>
          <Textarea
            variant="ghost"
            size="sm"
            bg="surface.secondary"
            rows={3}
            resize="vertical"
            isDisabled={isSubmitting}
            placeholder={locale['Enter anti-pattern']}
            {...register('content', {
              required: 'Required',
            })}
          />
        </Field>
      </Flex>
    </Stack>
  );
};
