// 3rd
import { z } from 'zod';

// App - Types
import {
  RequirementSeveritiesDto,
  castRequirementSeverityDtoToRequirementSeverity,
} from '@/types/security-framework/requirement/dtos/requirement-severity';
import {
  ActivityStatusesDto,
  castActivityStatusDtoToBoolean,
} from '@/types/security-framework/dtos/activity-status';
import type { Requirement } from '../../types/requirement';
import { ZodAntiPatternDto, castAntiPatternDtoToAntiPattern } from './anti-pattern';
import { ZodCountermeasureDto, castCountermeasureDtoToCountermeasure } from './countermeasure';

export const ZodRequirementDto = z.object({
  id: z.string(),
  categoryName: z.string().optional(),
  content: z.string(),
  description: z.string().optional(),
  requirementIdNumber: z.string(),
  cwes: z.string().array(),
  severity: z.enum(RequirementSeveritiesDto),
  activityStatus: z.enum(ActivityStatusesDto),
  countermeasures: z.array(ZodCountermeasureDto).optional(),
  antiPatterns: z.array(ZodAntiPatternDto).optional(),
});

export type RequirementDto = z.infer<typeof ZodRequirementDto>;

export const castRequirementDtoToRequirement = (
  dto: RequirementDto,
  framework: string = '',
  control: string = ''
): Requirement => ({
  framework,
  control,
  category: dto.categoryName,
  content: dto.content,
  description: dto.description,
  id: dto.id,
  isActive: castActivityStatusDtoToBoolean(dto.activityStatus),
  countermeasures: (dto.countermeasures || []).map(castCountermeasureDtoToCountermeasure),
  antiPatterns: (dto.antiPatterns || []).map(castAntiPatternDtoToAntiPattern),
  requirementIdInFramework: dto.requirementIdNumber,
  severity: castRequirementSeverityDtoToRequirementSeverity(dto.severity),
  cwes: dto.cwes,
});
