// 3rd
import { Flex, Skeleton, useToken } from '@chakra-ui/react';

// App - Types
import type { AntiPattern } from '../../types/anti-pattern';

// App - Other
import { Text } from '@/components/atoms/typography';
// import { Tag } from '@/components/molecules/tag';
// import { translateType } from '../translators/anti-pattern/type';

type AntiPatternCardProps = {
  antiPattern: AntiPattern;
};

export const AntiPatternCard = ({ antiPattern }: AntiPatternCardProps) => {
  const [defaultOutlineColor] = useToken('colors', ['border.primary']);

  return (
    <Flex
      alignItems="center"
      gap="lg"
      height="fit-content"
      outline={`1px solid ${defaultOutlineColor}`}
      borderRadius="sm"
      py="md"
      ps="lg"
      transition="150ms all ease-in-out"
    >
      <Text variant="detail" color="text.high-tone">
        {antiPattern.content}
      </Text>

      {/*<Tag variant="primary" size="old-md">*/}
      {/*  {translateType(antiPattern.type)}*/}
      {/*</Tag>*/}
    </Flex>
  );
};

const Loading = () => {
  const [defaultOutlineColor] = useToken('colors', ['border.primary']);

  return (
    <Flex
      alignItems="center"
      gap="lg"
      height="fit-content"
      outline={`1px solid ${defaultOutlineColor}`}
      borderRadius="sm"
      py="md"
      ps="lg"
      transition="150ms all ease-in-out"
    >
      <Skeleton w="180px" h="22px" />

      {/*<Skeleton w="85px" h="22px" />*/}
    </Flex>
  );
};

AntiPatternCard.Loading = Loading;
