// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { AntiPatternTypesDto } from '@/types/security-framework/dtos/anti-pattern-type';
import type { CreateAntiPattern } from '../types/anti-pattern';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

type CreateAntiPatternMutationPayload = {
  requirementId: string;
  model: CreateAntiPattern;
};

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityRequirementId: z.string(),
  content: z.string(),
  type: z.enum(AntiPatternTypesDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateAntiPatternRequestPayloadDto };
export type { RequestPayloadDto as CreateAntiPatternRequestPayloadDto };

// #######
// Request
// #######

export const createAntiPattern = async ({
  requirementId,
  model,
}: CreateAntiPatternMutationPayload): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityRequirementId: requirementId,
      content: model.content,
      type: 'Documentation',
    });

    return await apiClient.post('/SecurityFramework/CreateAntipattern', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateAntiPattern = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateAntiPattern = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateAntiPattern = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (payload: CreateAntiPatternMutationPayload) =>
      await createAntiPattern(payload),
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    createAntiPattern: mutate,
    isCreating: isPending,
    didCreationErrored: isError,
  };
};
