// React & Next
import { useMemo } from 'react';

// 3rd
import { createFilter } from 'react-select';
import { Skeleton, forwardRef } from '@chakra-ui/react';
import type { StyleProps, ComponentWithAs, As } from '@chakra-ui/react';

// App - Types
import type { InputProps } from '@/components/molecules/form';
import type { SecurityFramework } from '../../types/security-framework';

// App - Other
import Locale from '@/locale/en.json';
import { Select, CreatableSelect } from '@/components/molecules/form';

const locale = Locale.features.frameworks['select-security-framework-labels'];

type SelectSecurityFrameworkLabelsComponent = ComponentWithAs<
  As,
  SelectSecurityFrameworkLabelsProps
> & {
  Loading: typeof Loading;
};

type SelectSecurityFrameworkLabelsProps = StyleProps &
  Pick<InputProps, 'variant'> & {
    frameworks: SecurityFramework[];
    includeDefaultOption?: boolean | string;
    placeholder?: string;
    selected?: string[];
    onChange: (labels?: string[]) => void;
    onBlur?: () => void;
    isDisabled?: boolean;
    allowCreateOption?: boolean;
  };

export const SelectSecurityFrameworkLabels = forwardRef(
  (
    {
      variant,
      frameworks,
      includeDefaultOption,
      placeholder,
      selected,
      onChange,
      onBlur,
      isDisabled,
      allowCreateOption,
      ...props
    }: SelectSecurityFrameworkLabelsProps,
    ref
  ) => {
    const SelectComponent = useMemo(() => {
      return allowCreateOption ? CreatableSelect : Select;
    }, [allowCreateOption]);

    const selectedOptions = useMemo(() => {
      return (selected || []).map((label) => ({
        label,
        value: label,
      }));
    }, [selected]);

    const defaultOption = useMemo(
      () => ({
        value: '',
        label:
          typeof includeDefaultOption === 'string'
            ? includeDefaultOption
            : locale['Select from list...'],
      }),
      [includeDefaultOption]
    );

    const options = useMemo(() => {
      const distinctLabels = frameworks.reduce((set, framework) => {
        framework.labels.forEach((label) => {
          set.add(label);
        });

        return set;
      }, new Set<string>());

      const _options = Array.from(distinctLabels)
        .map((label) => ({
          label,
          value: label,
          isDisabled: selectedOptions.length >= 3,
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
          })
        );

      if (!!includeDefaultOption && _options.length > 1) {
        return [defaultOption, ..._options];
      }

      return _options;
    }, [defaultOption, frameworks, includeDefaultOption, selectedOptions.length]);

    return (
      <SelectComponent
        useBasicStyles
        isMulti
        variant={variant || 'old.outline'}
        size="sm"
        options={options}
        value={selectedOptions}
        placeholder={placeholder}
        selectedOptionColor="surface.brand.primary"
        onChange={(options) => {
          onChange(options.map((option) => option.value));
        }}
        onBlur={onBlur}
        isDisabled={isDisabled}
        ref={ref}
        closeMenuOnSelect={false}
        filterOption={createFilter({
          stringify: (option) => option.label,
        })}
        chakraStyles={{
          container: (styles) => ({
            maxW: '100%',
            w: 'fit-content',
            minW: '165px',

            ...styles,
            ...props,
          }),
          menuList: (styles) => ({
            ...styles,
            ...props,
          }),
          clearIndicator: (styles) => ({
            '& .chakra-icon': {
              width: '6px',
              height: '6px',
            },

            ...styles,
          }),
        }}
      />
    );
  }
) as SelectSecurityFrameworkLabelsComponent;

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

SelectSecurityFrameworkLabels.Loading = Loading;
