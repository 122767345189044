// 3rd
import { z } from 'zod';

// App - Types
import { StoryStatusesDto, castStoryStatusDtoToStoryStatus } from '@/types/story/dtos/story-status';
import {
  SecurityRequirementStatusesDto,
  castSecurityRequirementStatusDtoToSecurityRequirementStatus,
} from '@/types/story/security-requirements-analysis/dtos/security-requirement-status';
import type { StoryOccurrence } from '../../types/requirement';
import { ZodAntiPatternDto, castAntiPatternDtoToAntiPattern } from './anti-pattern';

// App - Other
import { normalizeDatesToISOString } from '@/utils/format';

export const ZodStoryOccurrencesDto = z.object({
  antiPatterns: z.array(ZodAntiPatternDto),
  applicationId: z.string().optional(),
  applicationName: z.string().optional(),
  lastModified: z.string(),
  requirementStatus: z.enum(SecurityRequirementStatusesDto),
  storyId: z.string(),
  storyName: z.string(),
  storyStatus: z.enum(StoryStatusesDto),
});

export type StoryOccurrencesDto = z.infer<typeof ZodStoryOccurrencesDto>;

export const castStoryOccurrencesDtoToStoryOccurrence = (
  dto: StoryOccurrencesDto
): StoryOccurrence => {
  return {
    id: dto.storyId,
    applicationId: dto.applicationId,
    applicationName: dto.applicationName,
    name: dto.storyName,
    status: castStoryStatusDtoToStoryStatus(dto.storyStatus),
    lastUpdated: normalizeDatesToISOString(dto.lastModified),
    antiPatterns: dto.antiPatterns.map(castAntiPatternDtoToAntiPattern),
    requirementStatus: castSecurityRequirementStatusDtoToSecurityRequirementStatus(
      dto.requirementStatus
    ),
  };
};
