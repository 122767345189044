// App - Types
import type { SecurityFrameworkType } from '@/types/security-framework/type';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework'].type;

export const translateSecurityFrameworkType = (type: SecurityFrameworkType): string => {
  if (type === 'custom') return locale['Custom'];

  if (type === 'industry') return locale['Industry'];

  if (type === 'clover') return locale['Clover'];

  return locale['Unknown'];
};
