// 3rd
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { RequirementOverview } from '../types/requirement';
import { ZodRequirementDto, castRequirementDtoToRequirement } from './dtos/requirement';
import {
  ZodStoryOccurrencesDto,
  castStoryOccurrencesDtoToStoryOccurrence,
} from './dtos/story-occurrences';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';
import { SecurityRequirementStatusGroups } from '@/types/story/security-requirements-analysis/security-requirement-status';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  applicationId: z.string().optional(),
  requirementId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityRequirementOverview: z.object({
    storyOccurrences: z.array(ZodStoryOccurrencesDto),
    securityRequirement: ZodRequirementDto,
  }),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getRequirementOverview = async (
  id: string,
  applicationId?: string
): Promise<RequirementOverview> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      applicationId ? { applicationId, requirementId: id } : { requirementId: id }
    );
    const res = await apiClient.post(`/SecurityFramework/GetSecurityRequirementOverview`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    const requirement = castRequirementDtoToRequirement(
      parsedRes.securityRequirementOverview.securityRequirement
    );

    const storiesOccurrences = parsedRes.securityRequirementOverview.storyOccurrences.map(
      castStoryOccurrencesDtoToStoryOccurrence
    );

    const sentToDevInStories = storiesOccurrences.filter((story) =>
      SecurityRequirementStatusGroups.sent_to_dev.includes(story.requirementStatus)
    );

    const coveredInStories = storiesOccurrences.filter((story) =>
      SecurityRequirementStatusGroups.covered.includes(story.requirementStatus)
    );

    const rejectedInStories = storiesOccurrences.filter((story) =>
      SecurityRequirementStatusGroups.irrelevant.includes(story.requirementStatus)
    );

    return {
      ...requirement,
      requestedInStories: storiesOccurrences,
      sentToDevInStories,
      coveredInStories,
      rejectedInStories,
    };
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseRequirementOverview = {
  id?: string;
  applicationId?: string;
  enabled?: boolean;
};

export const useRequirementOverview = ({
  id,
  applicationId,
  enabled = true,
}: UseRequirementOverview) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.requirementOverview(id!, applicationId),
    queryFn: async () => await getRequirementOverview(id!, applicationId),
    enabled: enabled && !!id,
  });

  return {
    requirementOverview: data,
    isFetchingRequirementOverview: isLoading || isFetching,
    didFetchingRequirementOverviewErrored: isLoadingError,
  };
};
