// React & Next
import { useMemo } from 'react';

// 3rd
import { Skeleton } from '@chakra-ui/react';
import type { StyleProps } from '@chakra-ui/react';

// App - Types
import type { SecurityFrameworkType } from '@/types/security-framework/type';
import type { SecurityFramework } from '../../types/security-framework';

// App - Other
import Locale from '@/locale/en.json';
import { Select } from '@/components/molecules/form';
import { translateSecurityFrameworkType } from '@/components/translators/security-framework/type';

const locale = Locale.features.frameworks['frameworks-select-requirements-framework-type'];

type SelectSecurityFrameworkTypeProps = StyleProps & {
  frameworks: SecurityFramework[];
  defaultOptionLabel?: string;
  selected?: SecurityFrameworkType;
  onChange: (type?: SecurityFrameworkType) => void;
  isDisabled?: boolean;
};

export const SelectSecurityFrameworkType = ({
  frameworks,
  defaultOptionLabel,
  selected,
  onChange,
  isDisabled,
  ...props
}: SelectSecurityFrameworkTypeProps) => {
  const defaultOption = useMemo(
    () => ({
      value: '',
      label: defaultOptionLabel || locale['Select a type'],
    }),
    [defaultOptionLabel]
  );

  const options = useMemo(() => {
    const distinctTypes = frameworks.reduce((set, framework) => {
      return set.add(framework.type);
    }, new Set<string>());

    const typeOptions = Array.from(distinctTypes).map((type) => ({
      value: type,
      label: translateSecurityFrameworkType(type as SecurityFrameworkType),
    }));

    if (typeOptions.length > 1) return [defaultOption, ...typeOptions];

    return typeOptions;
  }, [defaultOption, frameworks]);

  const selectedOption = useMemo(() => {
    if (selected) {
      return {
        value: selected,
        label: translateSecurityFrameworkType(selected),
      };
    }

    const hasDefaultOption = options.some((option) => option.value === defaultOption.value);

    if (hasDefaultOption) {
      return defaultOption;
    }

    return undefined;
  }, [defaultOption, options, selected]);

  return (
    <Select
      useBasicStyles
      variant="old.outline"
      size="sm"
      isDisabled={isDisabled}
      options={options}
      value={selectedOption}
      selectedOptionColor="surface.brand.primary"
      placeholder={defaultOptionLabel}
      onChange={(option) => {
        if (!option) {
          return;
        }

        onChange(option.value as SecurityFrameworkType);
      }}
      closeMenuOnSelect={false}
      chakraStyles={{
        container: (styles) => ({ ...styles, ...props }),
        menuList: (styles) => ({ ...styles, ...props }),
      }}
    />
  );
};

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

SelectSecurityFrameworkType.Loading = Loading;
