// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { ActivityStatusesDto } from '@/types/security-framework/dtos/activity-status';
import { SecurityFrameworksSourcesDto } from '@/types/security-framework/dtos/source';
import { SecurityFrameworksTypesDto } from '@/types/security-framework/dtos/type';
import type { CreateSecurityFramework } from '../types/security-framework';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  activityStatus: z.enum(ActivityStatusesDto),
  frameworkDescription: z.string().optional(),
  isDefault: z.boolean(),
  labels: z.array(z.string()),
  name: z.string(),
  source: z.enum(SecurityFrameworksSourcesDto),
  type: z.enum(SecurityFrameworksTypesDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateSecurityFrameworkRequestPayloadDto };
export type { RequestPayloadDto as CreateSecurityFrameworkRequestPayloadDto };

// #######
// Request
// #######

export const createSecurityFramework = async (model: CreateSecurityFramework): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      activityStatus: 'Active',
      frameworkDescription: model.description,
      isDefault: true,
      labels: model.labels,
      name: model.name,
      source: 'Custom',
      type: 'Custom',
    });

    return await apiClient.post('/SecurityFramework/CreateSecurityFramework', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateSecurityFramework = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateSecurityFramework = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateSecurityFramework) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateSecurityFramework) => await createSecurityFramework(model),
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    createSecurityFramework: mutate,
    isCreating: isPending,
    didCreationErrored: isError,
  };
};
