export const CountermeasureSnippetTypes = [
  'architecture',
  'code',
  'configuration',
  'documentation',
  'other',
  'UNKNOWN',
] as const;

export type CountermeasureSnippetType = (typeof CountermeasureSnippetTypes)[number];
