// App - Types
import type { ImportanceLevel } from '@/types/security-framework/importance-level';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework']['importance-level'];

export const translateImportanceLevel = (importance: ImportanceLevel): string => {
  if (importance === 'high') return locale['High'];

  if (importance === 'medium') return locale['Medium'];

  if (importance === 'low') return locale['Low'];

  return locale['Unknown'];
};

export const translateImportanceLevelToColor = (importance: ImportanceLevel): string => {
  if (importance === 'high') return '#EC8C7E';

  if (importance === 'medium') return '#FFCA45';

  if (importance === 'low') return '#82B8FE';

  return '#D7E1EE';
};

export const translateImportanceLevelToBgColor = (importance: ImportanceLevel): string => {
  if (importance === 'high') return '#FDE2E2';

  if (importance === 'medium') return '#FDFAE2';

  if (importance === 'low') return '#E2F0FD';

  return '#D7E1EE';
};
