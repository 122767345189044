// 3rd
import { z } from 'zod';

// App - Types
import {
  ImportanceLevelsDto,
  castImportanceLevelDtoToImportanceLevel,
} from '@/types/security-framework/dtos/importance-level';
import type { Control } from '../../types/control';
import { ZodRequirementDto, castRequirementDtoToRequirement } from './requirement';

export const ZodControlDto = z.object({
  id: z.string(),
  controlGroup: z.string().optional(),
  controlName: z.string(),
  description: z.string(),
  importanceScore: z.enum(ImportanceLevelsDto),
  securityRequirements: z.array(ZodRequirementDto),
});

export type ControlDto = z.infer<typeof ZodControlDto>;

export const castControlDtoToControl = (dto: ControlDto, frameworkName: string = ''): Control => ({
  id: dto.id,
  name: dto.controlName,
  description: dto.description,
  importance: castImportanceLevelDtoToImportanceLevel(dto.importanceScore),
  requirements: dto.securityRequirements.map((req) =>
    castRequirementDtoToRequirement(req, frameworkName, dto.controlName)
  ),
});
