// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import {
  ImportanceLevelsDto,
  castImportanceLevelToImportanceLevelDto,
} from '@/types/security-framework/dtos/importance-level';
import type { CreateControl } from '../types/control';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  controlGroup: z.string().optional(),
  controlName: z.string(),
  description: z.string(),
  importanceScore: z.enum(ImportanceLevelsDto),
  securityFrameworkId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateControlRequestPayloadDto };
export type { RequestPayloadDto as CreateControlRequestPayloadDto };

// #######
// Request
// #######

export const createControl = async (frameworkId: string, model: CreateControl): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      controlName: model.name,
      description: model.description,
      importanceScore: castImportanceLevelToImportanceLevelDto(model.importance),
      securityFrameworkId: frameworkId,
    });

    return await apiClient.post('/SecurityFramework/CreateSecurityControl', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateControl = {
  frameworkId: string;
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateControl = ({
  frameworkId,
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateControl) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateControl) => await createControl(frameworkId, model),
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    createControl: mutate,
    isCreating: isPending,
    didCreationErrored: isError,
  };
};
