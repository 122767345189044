export const SECURITY_FRAMEWORKS_QUERY_KEYS = {
  all: ['security-frameworks'] as const,
  securityFrameworks: () => [...SECURITY_FRAMEWORKS_QUERY_KEYS.all] as const,
  securityFrameworksRequirementsViolation: () =>
    [...SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(), 'requirement-violations'] as const,

  requirementOverview: (requirementId: string, applicationId?: string) =>
    [
      ...SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      'requirement-overview',
      requirementId,
      ...(applicationId ? [applicationId] : []),
    ] as const,
};
