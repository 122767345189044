// 3rd
import { z } from 'zod';

// App - Types
import {
  AntiPatternTypesDto,
  castAntiPatternTypeDtoToAntiPatternType,
} from '@/types/security-framework/dtos/anti-pattern-type';
import type { AntiPattern } from '../../types/anti-pattern';

export const ZodAntiPatternDto = z.object({
  id: z.string(),
  content: z.string(),
  type: z.enum(AntiPatternTypesDto),
});

export type AntiPatternDto = z.infer<typeof ZodAntiPatternDto>;

export const castAntiPatternDtoToAntiPattern = (dto: AntiPatternDto): AntiPattern => ({
  id: dto.id,
  content: dto.content,
  type: castAntiPatternTypeDtoToAntiPatternType(dto.type),
});
